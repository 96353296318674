import React, { useMemo } from "react";
import { List, SelectAll } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";

import { selectVersionConfig } from "fond/api";
import FeaturesList from "fond/map/featureSelection/FeaturesList";
import PropertiesPanel from "fond/project/featureProperties/PropertiesPanel";
import { selectLayersFromConfig } from "fond/utils/configurations";
import { useAppSelector } from "fond/utils/hooks";

import { ITabProps } from "./factory";

export const Widget: React.FC<ITabProps> = () => {
  const { selectedFeature } = useAppSelector((state) => state.project);
  const featureSelectionPopup = useAppSelector((state) => state.project.featureSelectionPopup);
  const versionId = useAppSelector((state) => state.project.versionId);
  const projectId = useAppSelector((state) => state.project.projectId);
  const config = useAppSelector((state) => selectVersionConfig(state, versionId));
  const layerConfigs = useMemo(() => selectLayersFromConfig(config, ["LAYER", "SUBLAYER", "CommentConfig"]), [config]);

  if (!selectedFeature && !featureSelectionPopup) {
    return (
      <Box height="100%" display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <SelectAll color="action" sx={{ mb: 2 }} />
          <Typography variant="body3">Select a feature to view</Typography>
        </Box>
      </Box>
    );
  }

  // We always render the FeatureList & control its visibility so that navigation between
  // both panels maintains the UI state of the feature list (e.g. filtering / collapsed states)
  return (
    <>
      {selectedFeature && <PropertiesPanel />}
      <Box display={featureSelectionPopup?.features && !selectedFeature ? "flex" : "none"} height="100%">
        <FeaturesList features={featureSelectionPopup?.features || []} config={config} layerConfigs={layerConfigs} projectId={projectId} />
      </Box>
    </>
  );
};

export const Icon: React.FC<ITabProps> = () => (
  <Tooltip title="Properties" arrow placement="right">
    <List />
  </Tooltip>
);
