import React, { useCallback, useMemo, useState } from "react";
import {
  CellClickedEvent,
  ColDef,
  GridOptions,
  IRowNode,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { Directions } from "@mui/icons-material";
import { Box, TextField, Typography } from "@mui/material";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { useDebounce } from "use-debounce";

import { highlightFeatures, selectFeature } from "fond/project/redux";
import { selectComment } from "fond/redux/comments";
import { Configuration } from "fond/types";
import { LayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { useAppDispatch } from "fond/utils/hooks";
import { AgGrid } from "fond/widgets";
import { IconButtonCellRenderer } from "fond/widgets/AgGrid";
import LayerIconCellRenderer from "fond/widgets/AgGrid/cellRenderer/LayerIcon";

interface FeaturesListProps {
  projectId: string;
  features: MapboxGeoJSONFeature[];
  config: Configuration;
  layerConfigs: Array<LayerConfig | SublayerConfig>;
}

interface IData {
  feature: MapboxGeoJSONFeature;
  layerConfig: LayerConfig | SublayerConfig;
}

const FeaturesList: React.FC<FeaturesListProps> = ({ config, features, layerConfigs, projectId }) => {
  const dispatch = useAppDispatch();
  const queryRef = React.useRef("");
  const [searchText, setSearchText] = useState("");
  const [debouncedSearch] = useDebounce(searchText, 500);

  /**
   * Callback function called when row selection changes withing the grid.
   * Enable zoom if at least one feature has geometry.
   */
  const onSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      const selectedNodes = event.api.getSelectedNodes();

      dispatch(
        highlightFeatures({
          projectId: projectId,
          features: selectedNodes.map(({ data }) => data.feature),
        })
      );
    },
    [dispatch, projectId]
  );

  const onRowDoubleClicked = useCallback(
    (event: RowDoubleClickedEvent<IData>) => {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(({ data }) => data?.feature);

      if (selectedData?.[0]?.properties?.commentID) {
        dispatch(selectComment({ commentID: selectedData[0].properties.commentID, features: selectedData, isFromFeaturesPopup: true }));
      } else {
        dispatch(selectFeature(selectedData[0]));
      }
    },
    [dispatch]
  );

  const rowData: IData[] = useMemo(() => {
    return features.map((feature) => {
      const layerConfig = layerConfigs.find((item) => item.Styles.includes(feature.layer.id));

      return {
        layerConfig: layerConfig,
        feature: feature,
      } as IData;
    });
  }, [features, layerConfigs]);

  /**
   * Determines the label value for a row group based on the type of layer
   */
  const groupValueGetter = useCallback(
    ({ data }: ValueGetterParams<IData>) => {
      if (!data) return "";
      const { layerConfig } = data;

      if (layerConfig.SubType === "COST_TO_SERVE" && layerConfig.ParentID) {
        return (config.Data.entities[layerConfig.ParentID] as LayerConfig).Label;
      } else {
        return `${layerConfig?.Label}`;
      }
    },
    [config]
  );

  /**
   * Determines the label value for a row based on the type of layer & feature properties
   */
  const rowValueGetter = useCallback(({ data }: ValueGetterParams<IData>) => {
    if (!data) return "";
    const { layerConfig, feature } = data;

    return `${layerConfig?.Label}${
      feature.properties?.FondID || feature.properties?.BiarriID ? ` (${feature.properties.FondID || feature.properties.BiarriID})` : ``
    }`;
  }, []);

  const columns: ColDef[] = useMemo(() => {
    return [
      {
        field: "layerConfig",
        headerName: "icon",
        cellStyle: { marginLeft: 25 },
        cellRenderer: LayerIconCellRenderer,
        cellRendererParams: {
          config: config,
        },
        width: 40,
        valueFormatter: () => "",
      },
      {
        headerName: "Layer",
        flex: 1,
        rowGroup: true,
        hide: true,
        valueGetter: groupValueGetter,
      },
      {
        headerName: "Label",
        flex: 1,
        cellStyle: { marginLeft: 16 },
        valueGetter: rowValueGetter,
      },
      {
        headerName: "menu",
        width: 50,
        cellRenderer: IconButtonCellRenderer,
        cellRendererParams: {
          icon: Directions,
          iconProps: {
            color: "primary",
          },
          iconButtonProps: {
            sx: {
              width: 24,
              height: 24,
              margin: 0,
              padding: 0,
              "& svg": {
                width: 16,
                height: 16,
              },
            },
          },
        },
        onCellClicked: (event: CellClickedEvent<IData>) => {
          onRowDoubleClicked(event);
        },
      },
    ];
  }, [config, groupValueGetter, onRowDoubleClicked, rowValueGetter]);

  const gridOptions: GridOptions = useMemo(
    () => ({
      rowGroupPanelShow: "never",
      groupDefaultExpanded: 1,
      groupDisplayType: "groupRows",
      groupRowRendererParams: {
        suppressCount: true,
      },
      headerHeight: 0,
      sideBar: false,
      onSelectionChanged: onSelectionChanged,
      onRowDoubleClicked: onRowDoubleClicked,
    }),
    [onRowDoubleClicked, onSelectionChanged]
  );

  /**
   * Filters each row of the grid based on the external search text the
   * user has entered.
   *
   * This is a basic search that looks for the search criteria in any column
   */
  const doesExternalFilterPass = (node: IRowNode<IData>): boolean => {
    const { data } = node;

    if (!data) return false;

    const {
      feature: { properties },
      layerConfig,
    } = data;

    return (
      layerConfig.Label.toLocaleLowerCase().includes(queryRef.current.toLocaleLowerCase()) ||
      (!!properties &&
        Object.keys(properties).filter((key) => {
          return `${properties[key]}`.toLocaleLowerCase().includes(queryRef.current.toLocaleLowerCase());
        }).length > 0)
    );
  };

  /**
   * Callback function that is passed the filter text as it changes
   */
  const handleOnFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    queryRef.current = event.currentTarget.value;
    setSearchText(event.currentTarget.value);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ p: 1 }}>
        <Typography variant="h6" sx={{ fontSize: 12, whiteSpace: "nowrap", marginRight: 0.5 }}>
          {rowData.length} features found
        </Typography>
        <TextField
          placeholder="Search for a feature..."
          onChange={handleOnFilterChange}
          size="small"
          sx={{
            maxWidth: 170,
            margin: 0,
            "& .MuiOutlinedInput-root": {
              height: 28,
              paddingRight: 0,
            },
            "& .MuiOutlinedInput-input": {
              fontSize: 12,
            },
            "& .MuiInputAdornment-root p": {
              fontSize: 12,
            },
          }}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <AgGrid
          autoSizeColumns={false}
          doesExternalFilterPass={doesExternalFilterPass}
          rowData={rowData}
          gridOptions={gridOptions}
          columnDefs={columns}
          variant="borderless"
          size="compact"
          externalSearchText={debouncedSearch}
          externalFilter
        />
      </Box>
    </Box>
  );
};

export default FeaturesList;
