import * as React from "react";
import { memo } from "react";
import { ICellRendererParams } from "@ag-grid-community/core";

import { InferredIcon } from "fond/map/Legend";
import { Configuration } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";

interface IProps extends ICellRendererParams<unknown, LayerConfig | SublayerConfig | LayerStyle> {
  config: Configuration;
}

const LayerIconCellRenderer: React.FC<IProps> = memo(({ value, config }: IProps) => {
  return value ? <InferredIcon entity={value} config={config} /> : null;
});

LayerIconCellRenderer.displayName = "LayerIconCellRenderer";

export default LayerIconCellRenderer;
